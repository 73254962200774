<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Inspecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Inspecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5>Inspecciones</h5>
              </div>
              <ul id="myTab" role="tablist" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    class="nav-link active"
                    @click="cambiaTab('principal')"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-Gestion"
                    data-toggle="tab"
                    href="#Gestion"
                    class="nav-link"
                    @click="cambiaTab('gestiona')"
                    v-if="inspeccion_id"
                    >Gestionar Inspección</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    id="tab-Gestion"
                    data-toggle="tab"
                    href="#Documentos"
                    class="nav-link"
                    @click="cambiaTab('documentos')"
                    v-if="
                      inspeccion_id &&
                        (conductor_id || vehiculo_id || remolque_id)
                    "
                    >Documentos</a
                  >
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <!-- Formulario -->
                <div id="Principal" class="tab-pane fade active show">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-1">
                        <label>Viaje id</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.viaje_id"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-2 text-center">
                        <label>Pre-operacional</label>
                        <input
                          type="checkbox"
                          class="form-control form-control-sm p-0"
                          v-model="preoperacional"
                          :disabled="form.formato_inspeccion"
                          @click="limpiarCampos"
                        />
                      </div>
                      <div class="col-md-2">
                        <label>Tipo de sitio:</label>
                        <select
                          class="form-control form-control-sm p-0"
                          v-model="form.sitio_type"
                          @input="cambiarTipo"
                          :disabled="estado_documento == 2"
                        >
                          <option
                            v-for="tipo in listasForms.sitio_types"
                            :key="tipo.numeracion"
                            :value="tipo.numeracion"
                          >
                            {{ tipo.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="form-group col-md-3"
                        v-if="form.sitio_type == 2"
                      >
                        <label>Punto de Gestión</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="form.sitio"
                          placeholder="Punto Gestión"
                          label="nombre"
                          :options="listasForms.puntos_gestion"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @input="seleccionarSitio"
                        ></v-select>
                      </div>
                      <div
                        class="form-group col-md-3"
                        v-if="form.sitio_type == 1"
                      >
                        <label>Sitio</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="form.sitio"
                          placeholder="Sitio"
                          label="nombre"
                          :options="listasForms.sitios"
                          :disabled="estado_documento == 2"
                          @input="seleccionarSitio"
                        ></v-select>
                      </div>

                      <div class="form-group col-md-4">
                        <label>Formato de Inspección</label>
                        <v-select
                          v-if="inspeVal == 0"
                          :class="[
                            $v.form.formato_inspeccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.formato_inspeccion"
                          placeholder="Formato Inspección"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.formatos_inspeccion"
                          :disabled="
                            preoperacionalDisabled ||
                              estado_documento == 2 ||
                              disabledFormato
                          "
                          @search="buscarFormatosInspeccion"
                        ></v-select>
                        <v-select
                          v-else
                          :class="[
                            $v.form.formato_inspeccion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.formato_inspeccion"
                          placeholder="Formato Inspección"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.formatos_inspeccion"
                          :disabled="
                            preoperacionalDisabled ||
                              estado_documento == 2 ||
                              disabledFormato
                          "
                        ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label>Cabezote</label>
                        <v-select
                          :class="[
                            $v.form.cabezote.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.cabezote"
                          placeholder="Placa"
                          label="placa"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.cabezotes"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @search="buscarCabezotes"
                          @input="validaRemolque"
                        ></v-select>
                        <div v-if="form.cabezote">
                          <div
                            v-if="
                              form.cabezote.ultimo_registro &&
                                form.cabezote.ultimo_registro != ''
                            "
                          >
                            <span
                              class="badge badge-warning"
                              v-if="
                                form.cabezote.ultimo_registro
                                  .fecha_hora_ultimo_reporte < fechaCorte
                              "
                            >
                              Ultimo reporte:
                              {{
                                form.cabezote.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                            <span class="badge badge-success" v-else>
                              Ultimo reporte:
                              {{
                                form.cabezote.ultimo_registro
                                  .fecha_hora_ultimo_reporte
                              }}
                            </span>
                          </div>
                          <div v-else>
                            <span class="badge badge-danger"
                              >No presenta fecha de reporte</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Remolque</label>
                        <v-select
                          :class="[
                            form.cabezote && form.cabezote.tipo_vehiculo_id == 1
                              ? $v.form.remolque.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                              : '',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.remolque"
                          placeholder="Placa"
                          label="placa"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.remolques"
                          :filterable="false"
                          :disabled="
                            !form.cabezote ||
                              form.cabezote.tipo_vehiculo_id != 1 ||
                              estado_documento == 2
                          "
                          @search="buscarRemolques"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Empresa</label>
                        <v-select
                          :class="[
                            $v.form.empresa.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          :filterable="false"
                          :disabled="estado_documento == 2"
                          @search="buscarEmpresas"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Conductor</label>
                        <v-select
                          :class="[
                            $v.form.conductor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="form.conductor"
                          placeholder="Conductor"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.conductores"
                          :filterable="false"
                          :disabled="!form.empresa || estado_documento == 2"
                          @search="buscarConductor"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row" v-if="viaje && form.cabezote">
                      <div class="form-group col-md-4">
                        <label>Sitio Cargue</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.sitio_origen"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Sitio Desargue</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.sitio_destino"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Despachado a</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="viaje.despachado_a"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label for="fecha_inicio">Fecha Inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_inicio"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="hora_inicio">Hora Inicial</label>
                        <!-- <input
                          type="time"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicio"
                          readonly
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicio"
                          :class="
                            $v.form.hora_inicio.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="fecha_fin">Fecha Final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form.fecha_fin"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="hora_fin">Hora Final</label>
                        <!-- <input
                          type="time"
                          class="form-control form-control-sm"
                          v-model="form.hora_fin"
                          readonly
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_fin"
                          :class="
                            $v.form.hora_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label>Responsable</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :value="responsable.nombre"
                          readonly
                        />
                        <input
                          v-model="form.responsable_id"
                          type="hidden"
                          class="form-control form-control-sm p-0"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Observaciones</label><br /><textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="form.observaciones"
                          :disabled="estado_documento == 2"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Gestión de inspecciones -->
                <div
                  id="Gestion"
                  class="tab-pane fade"
                  v-if="inspeccion_id && formato_inspeccion"
                >
                  <InspeccionGestiona
                    ref="InspeccionGestiona"
                  ></InspeccionGestiona>
                  <InspeccionImages ref="inspeccionImages"></InspeccionImages>
                </div>
                <!-- Documentos -->
                <div id="Documentos" class="tab-pane fade" v-if="inspeccion_id">
                  <InspeccionDocumentos
                    ref="InspeccionDocumentos"
                  ></InspeccionDocumentos>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-4">
                    <button class="btn btn-lg bg-secondary" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      v-if="estado_documento == 2"
                      class="btn btn-lg bg-navy"
                      @click="irVentanaOperaciones()"
                    >
                      <i class="fas fa-reply"></i><br />Ventanas operaciones
                    </button>
                    <button
                      class="btn btn-lg bg-primary"
                      v-show="
                        ($store.getters.can(
                          'hidrocarburos.inspecciones.edit'
                        ) ||
                          (viajeInspeccion &&
                            viajeInspeccion.val_inspeccion != null)) &&
                          !$v.form.$invalid &&
                          tab == 'principal' &&
                          (estado_documento == 1 || estado_documento == null)
                      "
                      @click="save"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                    <button
                      class="btn btn-lg bg-primary"
                      v-if="
                        ($store.getters.can(
                          'hidrocarburos.inspecciones.edit'
                        ) ||
                          (viajeInspeccion &&
                            viajeInspeccion.val_inspeccion != null)) &&
                          estado_documento == 1 &&
                          tab == 'gestiona' &&
                          $refs.InspeccionGestiona.flagGestionado
                      "
                      @click="$refs.InspeccionGestiona.save()"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                  <!-- Acción Guardar Estado -->
                  <div
                    class="col-md-3"
                    v-if="
                      $store.getters.can('hidrocarburos.inspecciones.edit') &&
                        estado_documento == 1 &&
                        tab == 'gestiona' &&
                        $refs.InspeccionGestiona.flagGestionado
                    "
                  >
                    <label class="m-0">Estado:</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="
                        $refs.InspeccionGestiona.estadoForm.estado_documento
                      "
                      v-if="
                        $store.getters.can('hidrocarburos.inspecciones.edit') &&
                          estado_documento == 1 &&
                          tab == 'gestiona'
                      "
                      @change="$refs.InspeccionGestiona.save()"
                      :disabled="
                        ($store.getters.can(
                          'hidrocarburos.inspecciones.edit'
                        ) &&
                          estado_documento != 1) ||
                          !$refs.InspeccionGestiona.flagGestionado
                      "
                    >
                      <!-- || !$refs.InspeccionGestiona.flagNovedadCerrada -->
                      <option
                        v-for="estado in $refs.InspeccionGestiona.listaForm
                          .estados_documento"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <!-- Alerta Val Inspección -->
                  <div
                    class="col-md-6"
                    v-if="
                      viajeInspeccion &&
                        viajeInspeccion.val_inspeccion != null &&
                        tab == 'gestiona' &&
                        !$refs.InspeccionGestiona.flagGestionado
                    "
                  >
                    <div
                      class="alert bg badge alert-default-warning col-md-12 border border-warning"
                    >
                      <h5>
                        <i class="icon fas fa-exclamation-triangle"></i>
                        Atención!
                      </h5>
                      <h6>
                        Se encuentran campos sin diligenciar del detalle, por
                        favor validar.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import moment from "moment";
import InspeccionGestiona from "./InspeccionGestiona";
import InspeccionImages from "./InspeccionImages";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
import InspeccionDocumentos from "./InspeccionDocumentos.vue";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "InspeccionForm",
  components: {
    Loading,
    InspeccionGestiona,
    InspeccionImages,
    vSelect,
    InspeccionDocumentos,
  },
  data() {
    return {
      cargando: false,
      tab: "principal",
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      formato_inspeccion: null,
      inspeVal: null,
      detalles_inspecciones: null,
      inspeccion_id: null,
      fechaActual: null,
      fechaCorte: null,
      estado: null,
      estado_documento: null,
      viaje_id: null,
      viaje: null,
      viajeInspeccion: null,
      inspeccion: null,
      conductor_id: null,
      vehiculo_id: null,
      remolque_id: null,
      disabledFormato: false,
      form: {
        id: null,
        det_postulacion_id: null,
        viaje_id: null,
        sitio: null,
        sitio_id: null,
        sitio_type: null,
        formato_inspeccion: null,
        cabezote: null,
        remolque: null,
        empresa: null,
        conductor: null,
        fecha_inicio: null,
        hora_inicio: null,
        fecha_fin: null,
        hora_fin: null,
        observaciones: null,
        responsable_id: this.$store.getters.getUser.id,
      },
      preoperacional: false,
      preoperacionalDisabled: false,
      listasForms: {
        sitio_types: [],
        puntos_gestion: [],
        formatos_inspeccion: [],
        empresas: [],
        conductores: [],
        cabezotes: [],
        remolques: [],
      },
    };
  },
  validations() {
    if (this.form.cabezote && this.form.cabezote.tipo_vehiculo_id == 1) {
      if (this.preoperacional) {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            cabezote: {
              required,
            },
            remolque: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      } else {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            cabezote: {
              required,
            },
            remolque: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      }
    } else {
      if (this.preoperacional) {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            cabezote: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      } else {
        return {
          form: {
            formato_inspeccion: {
              required,
            },
            cabezote: {
              required,
            },
            empresa: {
              required,
            },
            conductor: {
              required,
            },
            hora_inicio: {
              timeFormat,
            },
            hora_fin: {
              timeFormat,
            },
          },
        };
      }
    }
  },
  methods: {
    async getIndex() {
      this.inspeccion_id = this.$route.params.inspeccion_id;
      this.inspeccion = this.$route.params.inspeccion;
      this.inspeVal = this.$route.params.val;
      this.viajeInspeccion = this.$route.params.viaje;

      if (this.inspeccion_id !== undefined) {
        this.cargando = true;
        await axios
          .get("/api/hidrocarburos/inspecciones/show", {
            params: {
              inspeccion_id: this.inspeccion_id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              let result = response.data[0];
              this.estado = result.estado;
              this.estado_documento = result.estado_documento;
              this.formato_inspeccion = result.formato_inspeccion;
              this.detalles_inspecciones = result.detalles_inspecciones;

              // Validación para la vista de los documentos del conductor vehículo y remolque
              if (
                result.conductor_id ||
                result.vehiculo_id ||
                result.remolque_id
              ) {
                this.conductor_id = result.conductor_id
                  ? result.conductor_id
                  : null;
                this.vehiculo_id = result.vehiculo_id
                  ? result.vehiculo_id
                  : null;
                this.remolque_id = result.remolque_id
                  ? result.remolque_id
                  : null;
                this.$refs.InspeccionDocumentos.getIndex();
              }

              this.viaje_id = result.viaje_id;

              if (result.formato_inspeccion.pre_operacional == 1) {
                this.preoperacional = true;
              }

              // Datos del viaje
              /* if (result.viaje) {
              this.viaje = {
                sitio_origen: result.viaje.guia.det_guia.sitio_origen.nombre,
                sitio_destino: result.viaje.guia.det_guia.sitio_destino.nombre,
                despachado_a: result.viaje.guia.det_guia.despachado_a,
              };
            }*/

              // Se verifica el responsable de la inspección
              let responsable = this.form.responsable_id;
              if (result.responsable_id) {
                responsable = result.responsable.id;
                this.responsable = {
                  id: result.responsable.id,
                  nombre: result.responsable.name,
                };
              }

              // Se filtra el punto de gestion
              let sitio = null;

              let sitio_type = null;
              if (result.sitio_type) {
                if (result.sitio_type == "App\\Sitio") {
                  sitio_type = 1;
                  if (result.sitio) {
                    sitio = {
                      id: result.sitio.id,
                      nombre: result.sitio.nombre,
                      latitud: result.sitio.latitud,
                      longitud: result.sitio.longitud,
                    };
                  }
                } else {
                  sitio_type = 2;
                  if (result.sitio) {
                    sitio = {
                      id: result.sitio.id,
                      nombre: result.sitio.nombre,
                      latitud: result.sitio.latitud,
                      longitud: result.sitio.longitud,
                    };
                  }
                }
              }

              // Se filtra el remolque
              let remolque = null;
              if (result.remolque) {
                remolque = {
                  id: result.remolque.id,
                  placa: result.remolque.placa,
                };
              }

              if (result.sitio != null) {
                this.form = {
                  id: result.id,
                  viaje_id: result.viaje_id,
                  det_postulacion_id: result.det_postulacion_id,
                  formato_inspeccion: {
                    id: result.formato_inspeccion.id,
                    nombre: result.formato_inspeccion.nombre,
                    pre_operacional: result.formato_inspeccion.pre_operacional,
                  },
                  sitio,
                  sitio_type,
                  empresa: {
                    id: result.empresa.id,
                    razon_social: result.empresa.razon_social,
                  },
                  conductor: {
                    id: result.conductor.id,
                    nombre: `${result.conductor.nombres} ${result.conductor.apellidos}`,
                  },
                  cabezote: {
                    id: result.vehiculo.id,
                    placa: result.vehiculo.placa,
                    tipo_vehiculo_id: result.vehiculo.tipo_vehiculo_id,
                  },
                  remolque,
                  fecha_inicio: result.fecha_inicio,
                  hora_inicio: result.hora_inicio,
                  fecha_fin: result.fecha_fin,
                  hora_fin: result.hora_fin,
                  observaciones: result.observaciones,
                  responsable_id: responsable,
                };
              }

              this.validaRemolque();
            }
            this.cargando = false;
          });
        this.cargando = false;
      } else {
        if (
          this.viajeInspeccion !== null ||
          this.viajeInspeccion !== undefined
        ) {
          //faltan datos formato
          if (this.inspeccion !== undefined) {
            this.form.viaje_id = this.viajeInspeccion.id;
            if (this.inspeccion.pre_operacional == 1) {
              this.preoperacional = true;
            }
            this.form.formato_inspeccion = this.inspeccion;
            this.disabledFormato = true;
            this.form.cabezote = this.viajeInspeccion.vehiculo;
            if (this.viajeInspeccion.remolque !== null) {
              this.form.remolque = this.viajeInspeccion.remolque;
            }
            this.form.empresa = this.viajeInspeccion.transportadora;
            this.form.conductor = this.viajeInspeccion.conductor;
            this.form.conductor.nombre =
              this.form.conductor.nombres + " " + this.form.conductor.apellidos;
            this.form.sitio_type = this.$route.params.sitio_type;
            this.form.sitio = this.$route.params.sitio;
            this.form.sitio_id = this.form.sitio.id;
          } else {
            if (this.inspeVal == 0) {
              this.preoperacional = true;
              this.form.formato_inspeccion = this.inspeccion;
              this.form.det_postulacion_id = this.viajeInspeccion.id;
              this.form.cabezote = this.viajeInspeccion.vehiculo;
              if (this.viajeInspeccion.remolque !== null) {
                this.form.remolque = this.viajeInspeccion.remolque;
              }
              this.form.empresa = this.viajeInspeccion.det_solicitud_diaria
                ? this.viajeInspeccion.det_solicitud_diaria.empresa
                : this.viajeInspeccion.det_postulacion.det_solicitud_diaria
                    .empresa;
              this.form.empresa_id = this.viajeInspeccion.det_solicitud_diaria
                ? this.viajeInspeccion.det_solicitud_diaria.empresa.id
                : this.viajeInspeccion.det_postulacion.det_solicitud_diaria
                    .empresa.id;
              this.form.conductor = this.viajeInspeccion.conductor;
              this.form.conductor.nombre =
                this.form.conductor.nombres +
                " " +
                this.form.conductor.apellidos;
              this.form.sitio_type = this.$route.params.sitio_type;
              this.form.sitio = this.$route.params.sitio;
              this.form.sitio_id = this.form.sitio.id;
            } else {
              this.preoperacional = true;
              this.form.formato_inspeccion = this.inspeccion;
              this.form.viaje_id = this.viajeInspeccion.id;
              await this.buscarFormatosInspeccionManual();
              var subcadena = "Inspección Operacional Cargue Articulados";
              let formato_inspeccion = this.listasForms.formatos_inspeccion.filter(
                (formato) => {
                  return formato.nombre
                    .toLowerCase()
                    .includes(subcadena.toLowerCase());
                }
              );

              this.form.formato_inspeccion = formato_inspeccion[0];
              this.form.cabezote = this.viajeInspeccion.vehiculo;

              if (this.viajeInspeccion.remolque !== null) {
                this.form.remolque = this.viajeInspeccion.remolque;
              }
              this.form.empresa = this.viajeInspeccion.det_solicitud_diaria
                ? this.viajeInspeccion.det_solicitud_diaria.empresa
                : this.viajeInspeccion.det_postulacion.det_solicitud_diaria
                    .empresa;
              this.form.empresa_id = this.viajeInspeccion.det_solicitud_diaria
                ? this.viajeInspeccion.det_solicitud_diaria.empresa.id
                : this.viajeInspeccion.det_postulacion.det_solicitud_diaria
                    .empresa.id;
              this.form.conductor = this.viajeInspeccion.conductor;
              this.form.conductor.nombre =
                this.form.conductor.nombres +
                " " +
                this.form.conductor.apellidos;
              this.form.sitio_type = this.$route.params.sitio_type;
              this.form.sitio = this.$route.params.sitio;
              this.form.sitio_id = this.form.sitio.id;
            }
          }
        }
      }
    },

    limpiarCampos() {
      this.form.sitio = null;
      this.form.sitio_id = null;
      this.form.formato_inspeccion = null;
      this.form.cabezote = null;
      this.form.remolque = null;
      this.listasForms.formatos_inspeccion = [];
      this.listasForms.cabezotes = [];
      this.listasForms.remolques = [];
      this.viaje = null;
    },

    //lista sitio type

    getSitioType() {
      // sitio 1, punto gestion 2
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    cambiarTipo() {
      this.form.sitio_id = null;
      this.form.sitio = null;
    },

    seleccionarSitio() {
      if (this.form.sitio_type == 1 || this.form.sitio_type == 2) {
        if (this.form.sitio) {
          this.form.sitio_id = this.form.sitio.id;
        } else {
          this.form.sitio_id = null;
        }
      }
    },

    // Métodos para busqueda del formulario
    buscarSitios() {
      let me = this;
      me.cargando = true;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
          me.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarPuntosGestion() {
      let me = this;
      me.cargando = true;

      var url = "api/admin/puntos_gestion/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.puntos_gestion = respuesta;
          me.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarFormatosInspeccion(search, loading) {
      if (search != "") {
        // Pregunto si es preoperacional
        let sitio = null;
        let sitio_type = null;
        let pre_operacional = 0;
        let params = null;
        let tipo_operacion = 0;

        if (this.preoperacional) {
          pre_operacional = 1;
        } else {
          if (this.form.sitio) {
            sitio = this.form.sitio.id;
            sitio_type = this.form.sitio_type;
          }
        }

        if (this.inspeccion) {
          tipo_operacion = this.inspeccion.tipo_operacion;
          params = {
            sitio,
            sitio_type,
            pre_operacional,
            tipo_operacion,
          };
        } else {
          params = {
            sitio,
            sitio_type,
            pre_operacional,
          };
        }
        let me = this;
        me.cargando = true;

        loading(true);
        var url =
          "api/hidrocarburos/inspecciones/buscarformatosinspeccion?nombre=" +
          search;

        axios
          .get(url, { params })
          .then(function(response) {
            me.listasForms.formatos_inspeccion = response.data;
            me.cargando = false;
            loading(false);
          })
          .catch(function(error) {
            me.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async buscarFormatosInspeccionManual() {
      // Pregunto si es preoperacional
      let sitio = null;
      let sitio_type = null;
      let pre_operacional = 0;
      let params = null;
      let tipo_operacion = 0;

      if (this.preoperacional) {
        pre_operacional = 1;
      } else {
        if (this.form.sitio) {
          sitio = this.form.sitio.id;
          sitio_type = this.form.sitio_type;
        }
      }

      if (this.inspeccion) {
        tipo_operacion = this.inspeccion.tipo_operacion;
        params = {
          sitio,
          sitio_type,
          pre_operacional,
          tipo_operacion,
        };
      } else {
        params = {
          sitio,
          sitio_type,
          pre_operacional,
        };
      }
      let me = this;
      me.cargando = true;

      var url = "api/hidrocarburos/inspecciones/buscarformatosinspeccion";

      await axios
        .get(url, { params })
        .then(function(response) {
          me.listasForms.formatos_inspeccion = response.data;
          me.cargando = false;
        })
        .catch(function(error) {
          me.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarCabezotes(search, loading) {
      this.fechaActual = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaActual)
        .add(-2, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      if (search != "") {
        let operacion = 0;
        if (this.preoperacional) {
          operacion = 1;
        }
        let me = this;
        me.cargando = true;

        loading(true);
        var url =
          "api/hidrocarburos/inspecciones/buscarcabezote?placa=" + search;
        axios
          .get(url, {
            params: {
              operacion,
            },
          })
          .then(function(response) {
            me.listasForms.cabezotes = response.data;
            me.cargando = false;
            loading(false);
          })
          .catch(function(error) {
            me.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    validaOperacional() {
      this.limpiarCampos();
      if (!this.form.formato_inspeccion) {
        this.preoperacional = true;
      } else if (this.form.formato_inspeccion.pre_operacional) {
        this.preoperacional = true;
      } else {
        this.preoperacional = false;
      }
    },

    validaRemolque() {
      if (!this.form.cabezote || this.form.cabezote.tipo_vehiculo_id != 1) {
        this.form.remolque = null;
      } else if (this.form.cabezote.viajes) {
        this.form.viaje_id = this.form.cabezote.viajes.id;
        // Se llenan los datos del viaje
        this.viaje = {
          sitio_origen: this.form.cabezote.viajes.det_guia.sitio_origen.nombre,
          sitio_destino: this.form.cabezote.viajes.det_guia.sitio_destino
            .nombre,
          despachado_a: this.form.cabezote.viajes.det_guia.despachado_a,
        };
        // Se llenan los datos del remolque
        if (this.form.cabezote.viajes.det_guia.remolque) {
          this.form.remolque = {
            id: this.form.cabezote.viajes.det_guia.remolque.id,
            placa: this.form.cabezote.viajes.det_guia.remolque.placa,
          };
        }

        // Se llenan los datos de la empresa
        if (this.form.cabezote.viajes.det_guia.transportadora) {
          this.form.empresa = {
            id: this.form.cabezote.viajes.det_guia.transportadora.id,
            razon_social: this.form.cabezote.viajes.det_guia.transportadora
              .razon_social,
          };
        }

        // Se llenan los datos del conductor
        if (this.form.cabezote.viajes.det_guia.conductor) {
          this.form.conductor = {
            id: this.form.cabezote.viajes.det_guia.conductor.id,
            nombre: `${this.form.cabezote.viajes.det_guia.conductor.nombres} ${this.form.cabezote.viajes.det_guia.conductor.apellidos}`,
          };
        }
      }
    },

    buscarRemolques(search, loading) {
      if (search != "") {
        let me = this;
        me.cargando = true;
        loading(true);
        var url = "api/admin/remolques/lista?placa=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.remolques = respuesta;
            me.cargando = false;
            loading(false);
          })
          .catch(function(error) {
            me.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresas(search, loading) {
      this.listasForms.conductores = [];
      this.form.conductor = [];
      if (search != "") {
        let me = this;
        me.cargando = true;
        loading(true);
        var url = "api/admin/empresas/lista?razon_social=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            me.cargando = false;
            loading(false);
          })
          .catch(function(error) {
            me.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        me.cargando = true;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url, {
            params: {},
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.conductores = respuesta;
            me.cargando = false;
            loading(false);
          })
          .catch(function(error) {
            me.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        let data = this.getData();
        this.cargando = true;

        axios({
          method: "PUT",
          url: "/api/hidrocarburos/inspecciones",
          data: data,
        })
          .then((response) => {
            if (response.data.msj) {
              this.$swal({
                icon: "error",
                title:
                  "La ultima inspección del  viaje se encuentra en estado borrador, se debe finalizar antes de crear otra. ",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            } else {
              this.$route.params.inspeccion_id = response.data.id;
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title:
                  "Los datos de la inspección se guardaron exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getData() {
      // Se valida los datos para el formulario
      let tipoSitio = null;
      if (this.form.sitio_type == 1) {
        tipoSitio = "App\\Sitio";
      } else {
        tipoSitio = "App\\PuntoGestion";
      }

      // Remolque
      let remolque_id = null;
      if (this.form.remolque) {
        remolque_id = this.form.remolque.id;
      }

      // Se valida la fecha y hora de inicio de la insspección
      let fecha_inicio = this.form.fecha_inicio;
      let hora_inicio = this.form.hora_inicio;
      if (!this.form.fecha_inicio && !this.form.hora_inicio) {
        let ahora = new Date();
        let fecha = `${ahora.getFullYear()}-${ahora.getMonth() +
          1}-${ahora.getDate()}`;
        let hora = `${ahora.getHours()}:${ahora.getMinutes()}:${ahora.getSeconds()}`;
        fecha_inicio = fecha;
        hora_inicio = hora;
      }

      let data = {
        id: this.form.id,
        det_postulacion_id: this.form.det_postulacion_id,
        viaje_id: this.form.viaje_id,
        formato_inspeccion_id: this.form.formato_inspeccion.id,
        sitio_id: this.form.sitio_id,
        empresa_id: this.form.empresa.id,
        conductor_id: this.form.conductor.id,
        vehiculo_id: this.form.cabezote.id,
        sitio_type: tipoSitio,
        remolque_id,
        fecha_inicio,
        hora_inicio,
        fecha_fin: this.form.fecha_fin,
        hora_fin: this.form.hora_fin,
        observaciones: this.form.observaciones,
        responsable_id: this.form.responsable_id,
      };
      return data;
    },

    cambiaTab(tab) {
      this.tab = tab;
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Inspecciones");
    },

    irVentanaOperaciones() {
      return this.$router.replace("/Hidrocarburos/VentanasOperaciones");
    },
  },

  mounted() {
    this.getIndex();
    this.getSitioType();
    this.buscarSitios();
    this.buscarPuntosGestion();
  },
};
</script>
